import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// Overrides
import ButtonOverrides from "./components/Button";
import FormOverrides from "./components/Form";
import FormLabelOverrides from "./components/FormLabel";

const theme = extendTheme(
	{
		config: {
			initialColorMode: "light",
			useSystemColorMode: false,
		},
		fonts: {
			heading: 'Satoshi, "IBM Plex Sans Thai", sans-serif',
			body: 'Satoshi, "IBM Plex Sans Thai", sans-serif',
		},
		// ...tailwind,
		colors: {
			selfless: {
				"50": "#fffefe",
				"100": "#fefefd",
				"200": "#fdfcf9",
				"300": "#fbfaf5",
				"400": "#f8f6ee",
				"500": "#f5f2e7",
				"600": "#dddad0",
				"700": "#b8b6ad",
				"800": "#93918b",
				"900": "#787771",
			},
			caramel: {
				"50": "#fff9f6",
				"100": "#fff4ed",
				"200": "#ffe3d1",
				"300": "#ffd3b5",
				"400": "#ffb17e",
				"500": "#ff9047",
				"600": "#e68240",
				"700": "#bf6c35",
				"800": "#99562b",
				"900": "#7d4723",
			},
		},
		components: {
			Button: ButtonOverrides,
			FormLabel: FormLabelOverrides,
			Form: FormOverrides,
			TextArea: {},
			Input: {
				baseStyle: {
					bg: "white",
					element: {
						fontWeight: 500,
						bg: "white",
					},
					field: {
						fontWeight: 500,
						bg: "white",
					},
					addon: {
						fontWeight: 500,
					},
					group: {
						bg: "white",
					},
				},
				variants: {
					filled: {
						bg: "white",
					},
				},
			},
			Checkbox: {
				baseStyle: {
					control: {
						bg: "white",
					},
				},
			},
			Accordion: {
				baseStyle: {
					button: {
						fontWeight: 600,
					},
				},
			},
			Divider: {
				baseStyle: {
					borderColor: "selfless.600",
				},
			},
		},
		styles: {
			/** Add global styles */
			global: {
				"*": {
					scrollBehavior: "smooth",
				},
			},
		},
	},
	withDefaultColorScheme({ colorScheme: "caramel" })
);

export default theme;
