
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { isProductionBrowser } from "@/helpers/isEnvironment";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import "@fontsource/ibm-plex-sans-thai";
import { DefaultSeo, LocalBusinessJsonLd, OrganizationJsonLd, WebPageJsonLd, } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import React from "react";
import "react-notion-x/src/styles.css";
import "../logging/BrowserLogger";
import "../styles/globals.css";
import "../styles/made_mirage.css";
import "../styles/satoshi.css";
import theme from "../theme/theme";
/** Lazy load FirebaseProviders */
const FirebaseProviders: React.ComponentType<{
    children: React.ReactNode;
}> = dynamic(() => import("../firebase/FirebaseProviders").then((mod) => mod.FirebaseProviders as any), { ssr: false });
/** Lazy load FirebaseSDKProviders */
const FirebaseSDKProviders: React.ComponentType<{
    children: React.ReactNode;
}> = dynamic(() => import("../firebase/FirebaseProviders").then((mod) => mod.FirebaseSDKProviders as any), { ssr: false });
/** Lazy load FirebaseAnalyticsProvider */
const FirebaseAnalyticsProvider: React.ComponentType<{
    children: React.ReactNode;
}> = isProductionBrowser()
    ? dynamic(() => import("../firebase/FirebaseProviders").then((mod) => mod.FirebaseAnalyticsProvider as any), { ssr: false })
    : (props: any): JSX.Element => <>{props.children}</>;
/** Load the page view logger dynamically on the client side */
const PageViewLogger = isProductionBrowser()
    ? dynamic(() => import("../analytics/PageViewLogger"), {
        ssr: false,
    })
    : (props: any): JSX.Element => <>{props.children}</>;
/** Load the page view logger dynamically on the client side */
const FirebasePerformanceMonitoringProvider = isProductionBrowser()
    ? dynamic(() => import("../analytics/FirebasePerformanceMonitoringProvider"), {
        ssr: false,
    })
    : (props: any): JSX.Element => <>{props.children}</>;
/** Load the tracking scripts dynamically on the client side */
const TrackingScripts = isProductionBrowser()
    ? dynamic(() => import("../tracking/TrackingScripts").then((mod) => mod.TrackingScripts) as any, { ssr: false })
    : (props: any): JSX.Element => <>{props.children}</>;
/** Load the BreakpointIndicator dynamically in development */
const BreakpoinIndicator = dynamic(() => import("../components/BreakpointIndicator"), { ssr: false });
function MyApp({ Component, pageProps }: AppProps) {
    const { t, lang } = useTranslation();
    // TODO: Try move this to the maps
    React.useEffect(() => {
        if (typeof window !== "undefined") {
            // Disable Safari touch zoom behavior to prevent crashing on iOS
            // https://stackoverflow.com/a/39711930
            document.addEventListener("gesturestart", function (e) {
                e.preventDefault();
            });
        }
    });
    return (<>
			<Head>
				{/*
  Disable Safari touch zoom behavior
  https://stackoverflow.com/questions/4389932/how-do-you-disable-viewport-zooming-on-mobile-safari
  */}
				<meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'/>
				<link rel='icon' href='/favicon.png' sizes='32x32'/>
			</Head>

			<DefaultSeo openGraph={{
            type: "website",
            locale: lang,
            url: "https://selfless.studio",
            siteName: "SiteName",
            images: [
                {
                    url: lang === "en"
                        ? "https://selfless.studio/api/og?title=Selfless%20Studio&description=Selfless%20Studio%20is%20a%20creative%20studio%20that%20focuses%20on%20creating%20products%20that%20are%20useful%20and%20meaningful."
                        : "https://selfless.studio/api/og?title=Selfless%20Studio&description=Selfless%20Studio%20คือ%20สตูดิโอ%20ที่%20สร้าง%20สินค้า%20ที่%20มี%20ประโยชน์%20และ%20มี%20ความ%20หมาย",
                },
            ],
        }} titleTemplate='%s | Selfless Studio' description='Selfless Studio is a creative studio that focuses on creating products that are useful and meaningful.' canonical='https://selfless.studio' languageAlternates={[
            {
                hrefLang: "en",
                href: "https://selfless.studio",
            },
            {
                hrefLang: "th",
                href: "https://selfless.studio/th",
            },
        ]} themeColor='#ffffff'/>

			{/* JSONLD schemas for selfless.studio */}
			<WebPageJsonLd url={`https://selfless.studio`} title='Selfless Studio' description='Selfless Studio is a creative studio that focuses on creating products that are useful and meaningful.' images={["https://selfless.studio/favicon.png"]} datePublished={new Date().toISOString()} dateModified={new Date().toISOString()} authorName='Selfless Studio' publisherName='Selfless Studio' publisherLogo='https://selfless.studio/favicon.png' inLanguage={lang} id={`https://selfless.studio`}/>

			<LocalBusinessJsonLd type='LocalBusiness' id='https://selfless.studio' name='Selfless Studio' url='https://selfless.studio' description='Selfless Studio is a creative studio that focuses on creating products that are useful and meaningful.' address={{
            streetAddress: "Artisan Ratchade อาร์ติซาน รัชดา, 99 Thiam Ruam Mit Rd",
            addressLocality: "Huai Khwang",
            addressRegion: "Bangkok",
            postalCode: "10310",
            addressCountry: "Thailand",
        }} geo={{
            latitude: 13.768390170602107,
            longitude: 100.58260836840827,
        }} telephone='+66 098 995 4197' priceRange='$' logo='https://selfless.studio/favicon.png' image='https://selfless.studio/favicon.png' sameAs={[
            "https://www.facebook.com/selflessstudio",
            "https://www.instagram.com/selflessstudio_/",
            "https://www.tiktok.com/@selflessstudio",
            "https://www.pinterest.com/selflessstudio/",
            "https://lin.ee/COQbnCp",
        ]}/>

			<OrganizationJsonLd type='Organization' id='https://selfless.studio' name='Selfless Studio' url='https://selfless.studio' description='Selfless Studio is a creative studio that focuses on creating products that are useful and meaningful.' address={{
            streetAddress: "Artisan Ratchade อาร์ติซาน รัชดา, 99 Thiam Ruam Mit Rd",
            addressLocality: "Huai Khwang",
            addressRegion: "Bangkok",
            postalCode: "10310",
            addressCountry: "Thailand",
        }} geo={{
            latitude: 13.768390170602107,
            longitude: 100.58260836840827,
        }} telephone='+66 098 995 4197' priceRange='$' logo='https://selfless.studio/favicon.png' image='https://selfless.studio/favicon.png' sameAs={[
            "https://www.facebook.com/selflessstudio",
            "https://www.instagram.com/selflessstudio_/",
            "https://www.tiktok.com/@selflessstudio",
            "https://www.pinterest.com/selflessstudio/",
            "https://lin.ee/COQbnCp",
        ]}/>

			{/* Tracking Scripts */}
			<TrackingScripts />
			<FirebaseProviders>
				<FirebaseSDKProviders>
					<ChakraProvider theme={theme}>
						<CSSReset />
						<Component {...pageProps}/>
						<BreakpoinIndicator />
					</ChakraProvider>

					{/* Custom Analytics */}
					<FirebaseAnalyticsProvider>
						<PageViewLogger />
					</FirebaseAnalyticsProvider>

					{/* Performance Analytics */}
					<FirebasePerformanceMonitoringProvider />
				</FirebaseSDKProviders>
			</FirebaseProviders>
		</>);
}
const __Next_Translate__Page__186defb26d9__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__186defb26d9__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  