import { theme } from '@chakra-ui/react';

const FormLabelOverrides: typeof theme['components']['FormLabel'] = {
  // @ts-expect-error
  baseStyle: {
    fontSize: 'sm',
    fontWeight: '500',
    mb: '1',
  },
};

export default FormLabelOverrides;
