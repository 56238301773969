import type { NextApiRequest } from 'next';

export const isProductionBrowser = (): boolean => {
  const isBrowser = typeof window !== 'undefined';
  // Early return if not in Browser
  if (!isBrowser) return false;

  const isProduction = process.env.NODE_ENV === 'production';
  // Check if we are on selfless.studio
  const isSelflessStudio = window.location.hostname === 'selfless.studio';
  return isBrowser && isProduction && isSelflessStudio;
};

export const isProductionServer = (req: NextApiRequest): boolean => {
  const isServer = typeof window === 'undefined';
  const isProduction = process.env.NODE_ENV === 'production';
  // Check request hostname is from selfless.studio
  const isSelflessStudio = req.headers.host === 'selfless.studio';
  return isServer && isProduction && isSelflessStudio;
};

export const isStagingBrowser = (): boolean => {
  const isBrowser = typeof window !== 'undefined';
  // Early return if not in Browser
  if (!isBrowser) return false;

  const isProduction = process.env.NODE_ENV === 'production';
  // Check if we are on selfless.studio
  const isSelflessStudio =
    window.location.hostname === 'shop.development.selfless.studio';
  return isBrowser && isProduction && isSelflessStudio;
};

export const isStagingServer = (req: NextApiRequest): boolean => {
  const isServer = typeof window === 'undefined';
  const isProduction = process.env.NODE_ENV === 'production';
  // Check request hostname is from selfless.studio
  const isSelflessStudio =
    req.headers.host === 'shop.development.selfless.studio';
  return isServer && isProduction && isSelflessStudio;
};
