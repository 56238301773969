import { Roarr as log } from 'roarr';

import { createLogWriter } from '@roarr/browser-log-writer';

if (typeof window !== 'undefined') {
  // @ts-ignore
  globalThis.ROARR = globalThis.ROARR ?? {};
  // @ts-ignore
  globalThis.ROARR.write = createLogWriter();

  // Enable logging in development mode by default
  if (process.env.NODE_ENV === 'development') {
    localStorage.setItem('ROARR_LOG', 'true');
  }
}

const editorLog = log.child({ namespace: 'client:editor' });
const cartLog = log.child({ namespace: 'client:cart' });
const checkoutLog = log.child({ namespace: 'client:checkout' });
const uploadLog = log.child({ namespace: 'firebase:upload' });

export { cartLog, checkoutLog, editorLog, log, uploadLog };
