import { theme } from '@chakra-ui/react';

const FormOverrides = {
  baseStyle: {
    helperText: {
      mt: 1,
      fontWeight: 500,
    },
  },
};

export default FormOverrides;
