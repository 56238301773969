import { theme } from '@chakra-ui/react';

const ButtonOverrides: typeof theme['components']['Button'] = {
  sizes: {
    // @ts-expect-error
    xl: {
      py: 4,
      fontSize: 'lg',
      fontWeight: 'bold',
    },
  },
  variants: {
    // @ts-expect-error
    black: {
      backgroundColor: 'black',
      color: 'white',
      border: '2px',
      _hover: {
        bg: 'white',
        color: 'black',
        borderColor: 'black',
      },
    },
  },
};

export default ButtonOverrides;
